
//const API_BASE_URL = "http://localhost:8080"

//const API_BASE_URL = "https://service-payment-tafz.onrender.com"

//active
  
const API_BASE_URL = "https://serverorders.onrender.com"
 
export default API_BASE_URL; 
               