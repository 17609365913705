import React from 'react'
import Reusenavbar from './reusenavbar'
import Sidebar from '../components/sidebar'
import { useFormik } from 'formik'
import * as Yup from "yup"
import axios from 'axios'
import { useNavigate, useLocation } from 'react-router-dom'
import Footer from '../components/footer'
import "../../App.css"


import API_BASE_URL from "../components/config";


const ReuseViewAndEditJob = (params) => {

    const location = useLocation();
    const jobData = location.state;
    console.log("jobData", jobData)

    const navigate = useNavigate();
    //console.log("details", params.value1);

    //present date
  const date = new Date();
  const dateTimeString = date.toLocaleString();




  const formik = useFormik({
    initialValues : {       
        postingDate : dateTimeString, 
        jobname : jobData.jobname ,
        jobtype : jobData.jobtype,  
        jobdescription : jobData.jobdescription,
        KeyResponsibilities : jobData.KeyResponsibilities,
        skills : jobData.skills    
    },
    validationSchema : Yup.object({
         
        jobname : Yup.string().required("job name Required"),
        jobtype : Yup.string().required("Specify job Type"),
        jobdescription : Yup.string().required("job Description Required"),
        KeyResponsibilities : Yup.string().required("Key Responsibilities Required"),
        skills : Yup.string().required("skills Required")
    }),
    onSubmit :async(values, {resetForm})=>{
        //console.log("form values", values);

        await axios.put(`${API_BASE_URL}/editjob/${jobData._id}`, values).then(res=>{
            alert(res.data.message);

            if(res.data.status === 200){
                navigate('/alljobs');
            }
        }
            ).catch(err=>console.log(err))


       

    }
    
})



  return (
    <div>
        {/* Begin page */}
    <div id="layout-wrapper">

     <Reusenavbar value1 ={params.value1} value2 = {params.value2} value3 = {params.value3}/>


{/* removeNotificationModal */}
<div id="removeNotificationModal" className="modal fade zoomIn" tabIndex="-1" aria-hidden="true">
    <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
            <div className="modal-header">
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" id="NotificationModalbtn-close"></button>
            </div>
            <div className="modal-body">
                <div className="mt-2 text-center">
                    <lord-icon src="https://cdn.lordicon.com/gsqxdxog.json" trigger="loop" colors="primary:#f7b84b,secondary:#f06548" style={{width:"100px",height:"100px"}}></lord-icon>
                    <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
                        <h4>Are you sure ?</h4>
                        <p className="text-muted mx-4 mb-0">Are you sure you want to remove this Notification ?</p>
                    </div>
                </div>
                <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
                    <button type="button" className="btn w-sm btn-light" data-bs-dismiss="modal">Close</button>
                    <button type="button" className="btn w-sm btn-danger" id="delete-notification">Yes, Delete It!</button>
                </div>
            </div>

        </div>{/* /.modal-content */}
    </div>{/* /.modal-dialog */}
</div>{/* /.modal */}
        {/* ========== App Menu ========== */}
        <div className="app-menu navbar-menu">
            {/* LOGO */}

           {/* sidebar start */}
           <Sidebar value1={params.value1} value2 = {params.value2} value3={params.value3}/>
            {/* sidebar end */}

        </div>
        {/* Left Sidebar End */}
        {/* Vertical Overlay*/}
        <div className="vertical-overlay"></div>

        {/* ============================================================== */}
        {/* Start right Content here */}
        {/* ============================================================== */}
        <div className="main-content">

            <div className="page-content">
                <div className="container-fluid">

                    {/* start page title */}
                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                <h4 className="mb-sm-0">Update Job</h4>

                                <div className="page-title-right">
                                    <ol className="breadcrumb m-0">
                                        <li className="breadcrumb-item"><a href='#a'>Application</a></li>
                                        <li className="breadcrumb-item active">Update Job</li>
                                    </ol>
                                </div>

                            </div>
                        </div>
                    </div>
                    {/* end page title */}

                    <div className="row justify-content-center">
                        <div className="col-xxl-12">
                            <div className="card">

                                <form className="needs-validation form" id="invoice_form" onSubmit={formik.handleSubmit}  autoComplete='off'>
                                    <div className="card-body border-bottom border-bottom-dashed p-4">
                                        <div className="row">

                                            <div className='row'>
                                            <div className='col-lg-4 col-0'></div>
                                                <div className='col-lg-4 col-12'>
                                            {params.value3 && 
                                                    <div className="profile-user mx-auto  mb-3">
                                                        {/* <input id="profile-img-file-input" type="file" className="profile-img-file-input" /> */}

                                                        <label htmlFor="profile-img-file-input" className='addinvoice_lable'  tabIndex="0" style={{width:"20rem"}}>
                                                            
                                                            <span className="overflow-hidden  d-flex align-items-center justify-content-left rounded" >

                                                                <img src={`${API_BASE_URL}/companyprofileimg/${params.value3[0]?.company_logo}`}  className="card-logo card-logo-dark user-profile-image img-fluid  c_profile_addinvoice_img" alt="logo dark" />
 
                                                                <span className='c_profile_name c_profile_addinvoice_name' >{params.value3[0]?.company_name}</span>
                                                                
                                                                {/* <img src={params.value3?.[0].company_logo} className="card-logo card-logo-light user-profile-image img-fluid" alt="logo light" /> */}
                                                            </span>
                                                        </label>
                                                    </div>
                                                }
                                                </div>
                                                <div className='col-lg-4 col-0'></div>
                                            </div>
                                            
                                            <div className="col-lg-12 col-12">
                                                <div className="row g-3">


                                                <label className='col-lg-4 col-12' htmlFor="date-field">Date</label>
                                                <div className='col-lg-8 col-12 mb-3'>
                                                <input type="text" className="form-control bg-light border-0 flatpickr-input" id="date-field" data-provider="flatpickr" data-time="true" placeholder="Select Date-time" name ="postingDate" readOnly = "readonly"  {...formik.getFieldProps("postingDate")}/> 
                                                </div>


                                                <label className='col-lg-4 col-12'>Job Name</label>
                                                <div className='col-lg-8 col-12 mb-3'>
                                                <input className="form-control bg-light border-0" id="clientname"  name="jobname" {...formik.getFieldProps("jobname")} placeholder='Enter Job Name'/>
                                                                                                                                                            
                                                    {(formik.touched.jobname && formik.errors.jobname) ? <small style={{color:"red"}}>{formik.errors.jobname}</small> : null}
                                                </div>


                                            
                                                <label className='col-lg-4 col-12'>Job Type</label>
                                                <div className='col-lg-8 col-12 mb-3'>
                                                <input type="text" className="form-control bg-light border-0" data-plugin="cleave-phone" id="clientContactno" placeholder="Enter Job type" name='jobtype' {...formik.getFieldProps("jobtype")} />
                                                   
                                                    {(formik.touched.jobtype && formik.errors.jobtype) ? <small style={{color:"red"}}>{formik.errors.jobtype}</small> : null}
                                                </div>

                                           

                                                <label className='col-lg-4 col-12'>Job Description</label>
                                                <div className='col-lg-8 col-12 mb-3'>
                                                <textarea className="form-control bg-light border-0" id="requirementdetails" rows="3" placeholder="Enter Job Description" name="jobdescription" {...formik.getFieldProps("jobdescription")} ></textarea>
                                                           
                                                    {(formik.touched.jobdescription && formik.errors.jobdescription) ? <small style={{color:"red"}}>{formik.errors.jobdescription}</small> : null}
                                                </div>


                                                <label className='col-lg-4 col-12'>Key Responsibilities</label>
                                                <div className='col-lg-8 col-12 mb-3'>
                                                    <textarea className="form-control bg-light border-0" id="requirementdetails" rows="3" placeholder="Enter Key Responsibilities" name="KeyResponsibilities" {...formik.getFieldProps("KeyResponsibilities")} ></textarea>

                                                    <div style={{backgroundColor:"#6cf0f5", marginTop:"10px"}}>
                                                    <p> <span style={{color:"red"}}>Note</span> : Seperate Each Sentence With pipe(|) symbol.</p>
                                                    </div>
                                                           
                                                    {(formik.touched.KeyResponsibilities && formik.errors.KeyResponsibilities) ? <small style={{color:"red"}}>{formik.errors.KeyResponsibilities}</small> : null}

                                                   
                                                </div>


                                                <label className='col-lg-4 col-12'>Skills</label>
                                                <div className='col-lg-8 col-12 mb-3'>
                                                    <textarea className="form-control bg-light border-0" id="requirementdetails" rows="3" placeholder="Enter skills" name="skills" {...formik.getFieldProps("skills")} ></textarea>

                                                    <div style={{backgroundColor:"#6cf0f5", marginTop:"10px"}}>
                                                    <p> <span style={{color:"red"}}>Note</span> : Seperate Each Sentence With pipe(|) symbol.</p>
                                                    </div>
                                                           
                                                    {(formik.touched.skills && formik.errors.skills) ? <small style={{color:"red"}}>{formik.errors.skills}</small> : null}

                                                   
                                                </div>
                                             
                                                                                                    
                                             </div>
                                                
                                            </div>
                                            {/*end col*/}
                                            
                                        </div>
                                        {/*end row*/}
                                    </div>
                                    
                                    <div className="card-body p-2">
                                                                                                                                                         
                                        <div className="hstack gap-2 justify-content-end d-print-none mt-4">
                                        {/* <button type="submit" className="btn btn-info"><i className="ri-printer-line align-bottom me-1"></i> Save</button> */}
                                            <button type="submit" className="btn btn-info">Submit</button>
                                            
                                        </div>
                                    </div>

                                </form>
                            </div>
                        </div>
                        {/*end col*/}
                    </div>
                    {/*end row*/}


                </div>
                {/* container-fluid */}
            </div>
            {/* End Page-content */}

            <Footer value3 ={params.value3}/>
        </div>
        {/* end main content*/}

    </div>
    {/* END layout-wrapper */}

    {/*start back-to-top*/}
    <button onclick="topFunction()" className="btn btn-danger btn-icon" id="back-to-top">
        <i className="ri-arrow-up-line"></i>
    </button>
    {/*end back-to-top*/}

    {/* preloader
    <div id="preloader">
        <div id="status">
            <div className="spinner-border text-primary avatar-sm" role="status">
                <span className="visually-hidden">Loading...</span>
            </div>
        </div>
    </div> */}

   
    </div>
  )
}

export default ReuseViewAndEditJob